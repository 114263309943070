<template>
    <ModalsBase modalWidth="max-w-[460px]">
        <template #header>
            <div class="text-center mb-8">
                <h2 class="mb-3">Upload Video</h2>
            </div>
        </template>
        <template #body>
            <form @submit.prevent="uploadVideos">
                <div class="mb-4">
                    <Input type="text" id="title" label="Title" placeholder="Enter video title" :disabled="uploadLoading":isRequired="true"  v-model="title" />
                </div>
                <div class="mb-4">
                    <Input id="file" type="file" lg="true" inputClassNew="flex-col text-center !gap-2 !py-5" :accept="'video/mp4,video/webm'" supported-files="mp4,webm,avi" :supportedFileSize="maxSizeMB" :disabled="uploadLoading" :isRequired="true"  @files="handleFile" />
                </div>

                <div class="attachment-wrap flex items-center justify-between gap-3 py-3 px-3 border border-[#D1D1D1] rounded-md last:mb-0" v-if="file">
                    <div class="flex items-center gap-3">
                        <div class="icon">
                            <div class="w-11 h-11 flex items-center justify-center p-2 bg-[#F2F2F2] rounded-full">
                                <img src="/images/069-jpg file.svg" class="h-full" alt="">
                            </div>
                        </div>
                        <div class="attach-content min-w-0">
                            <p class="mb-1 leading-none text-sm text-inactiveTabs truncate overflow-ellipsis">{{ truncateString(file.name,10,7,5) }}</p>
                            <p class="mb-0 leading-none text-sm text-themegray">{{ formatFileSize(file.size) }}</p>
                        </div>
                    </div>
                    <button class="bg-black text-white text-[11px] leading-none px-3 py-2 rounded-full" @click="removeFile()">Remove</button>
                </div>
 
                <!-- <div class="uploading-file border-t border-[#BDBDBD] pt-4">
                    <div
                        class="attachment-wrap flex items-center justify-between p-3 border border-[#D1D1D1] rounded-md mb-4 last:mb-0">
                        <div class="flex items-center gap-3 w-full">
                            <div class="icon">
                                <div class="w-11 h-11 flex items-center justify-center bg-[#F2F2F2] rounded-full">
                                    <img src="/images/docx_icon.svg" alt="">
                                </div>
                            </div>
                            <div class="attach-content w-full">
                                <div class="flex items-center justify-between">
                                    <p class="mb-1 leading-none text-sm text-inactiveTabs font-medium">Uploading...</p>
                                    <button class="mb-1 leading-none text-xs text-inactiveTabs font-medium">
                                        <Icon name='radix-icons:cross-1' size="10" />
                                    </button>
                                </div>
                                <div class="flex items-center justify-between mb-2">
                                    <p class="mb-0 leading-none text-xs text-themegray">Videoone.mp4 | Size: 24mb</p>
                                    <span class="mb-1 leading-none text-xs text-inactiveTabs font-medium">40%</span>
                                </div>
                                <div class="progress-wrap h-2 bg-[#E0E0E0] rounded-full">
                                    <div class="progress w-[40%] bg-secondary h-full rounded-full"></div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div> -->
                <div class="flex gap-4 mt-6">
                    <Button variant="outline" class="w-full" @click="closeModal()" :disabled="uploadLoading">CANCEL</Button>
                    <Button type="submit" class="w-full" :disabled="uploadLoading" :loading="uploadLoading">UPLOAD</Button>
                </div>
            </form>
        </template>
    </ModalsBase>
</template>

<script setup>

const emit = defineEmits(['close'])

const closeModal = () => {
    emit('close', 'upload', false)
}

const { uploadMyVideo ,getMyVideos} = await useTrainingVideo()

const errorStore = useErrorStore()
const uploadLoading = ref(false)
const title = ref('')
const file = ref(null)

const acceptedMimeTypes = ['video/mp4', 'video/webm'];
const maxSizeMB = 50; // 50 MB limit

function validateFile(file, acceptedMimeTypes, maxSizeMB) {

  // Get the file size in MB
  const fileSizeMB = file.size / (1024 * 1024);

  // Check if the file type (MIME type) is valid
  if (!acceptedMimeTypes.includes(file.type)) {
    file.value = null
    errorStore.showError(`Invalid file type. Accepted types are: ${acceptedMimeTypes.join(', ')}`,true,'warning')
    return false;
  }

  // Check if the file size is within the allowed limit
  if (fileSizeMB > maxSizeMB) {
    file.value = null
    errorStore.showError(`File size exceeds the maximum limit of ${maxSizeMB} MB`,true,'warning')
    return false;
  }

  // If all checks pass, return true
  return true;
}

const handleFile = (f) =>{

    console.log("Selected File : ",f)
    if(!f){
        alert("Please select a file.")
        return
    }
    if(validateFile(f[0],acceptedMimeTypes,maxSizeMB)){
        file.value = f[0]
    }
    return
}

const uploadVideos = async() => {
    try {
        console.log("File : ",file.value)
        if(!file.value){
            errorStore.showError('Please Select File.',true,'warning')
            return
        }
        // if (file.value.size > ) {
            
        // }
        uploadLoading.value = true
        let formData = new FormData()
        formData.append('title',title.value)
        formData.append('bucket',"media")
        formData.append('size',file.value.size)
        formData.append('file',file.value)
        await uploadMyVideo(formData)

    } catch (error) {
        console.log("Error : ",error)
    }
    finally{
        uploadLoading.value = false;
        if(file.value){
            file.value = null;
            closeModal()
        }
    }
}

function formatFileSize(bytes) {
  const MB = bytes / (1024 * 1024);
  if (MB < 1) {
    const KB = bytes / 1024;
    return KB.toFixed(2) + ' KB';
  }
  return MB.toFixed(2) + ' MB';
}

const truncateString = (str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) => {
  if (str.length <= firstCharCount + endCharCount) {
    return str; // No truncation needed
  }

  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCharCount);
  const dots = '.'.repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
}

const removeFile = () =>{
    file.value = null;
}
</script>
