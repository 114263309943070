<template>
    <div>
        <div class="flex flex-wrap items-center justify-between mb-10">
            <h3 class="font-semibold">Videos</h3>
            <div class="flex gap-3 md:gap-5 items-center">
                <!-- <Button icon="material-symbols:center-focus-strong-outline-rounded" @click="toggleDialog('record', true)">Record</Button> -->
                <Button icon="material-symbols:add" @click="toggleDialog('upload', true)">Upload</Button>
            </div>
        </div>
        <TabGroup>
            <TabList class="flex gap-3 md:gap-5 mb-7">
                <Tab :class="tabButton">
                    My videos
                </Tab>
                <Tab :class="tabButton">
                    <!-- {{ user.profile.account_type == 'va' ? "Client Videos" : "Virtual Assistant Videos"}} -->
                      Associates videos
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        <LazyVideoCard v-for="video in $state.my_videos" :key="video.id" :data="video" @openDialog="toggleDialog" />
                        <div class="flex flex-col justify-center items-center gap-3 min-h-52 text-themegray bg-[#EFEFEF] border border-dashed border-themegray rounded-xl cursor-pointer" @click="toggleDialog('upload', true)">
                            <Icon name='material-symbols:add-circle-outline' size="60" />
                            <span class="text-sm">Add Video</span>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        <LazyVideoCard v-for="video in $state.assosiate_videos" :key="video.id" :data="video" @openDialog="toggleDialog" />
                    </div>
                </TabPanel>
            </TabPanels>
        </TabGroup>
    </div>
    <ModalsEditVideo v-if="dialog.edit" :data="videoDetail" @close="toggleDialog('edit', false)" />
    <ModalsDeleteVideo v-if="dialog.delete" :data="videoDetail" @close="toggleDialog('delete', false)" />
    <ModalsUploadVideo v-if="dialog.upload" @close="toggleDialog('upload', false)" />
    <!-- <ModalsRecordVideo v-if="dialog.record" @close="toggleDialog('record', false)" /> -->
</template>

<script setup>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

definePageMeta({
    layout: "dashboard-layout",
    middleware:'check-auth'
})
const { getMyVideos,getAssociateVideos } = await useTrainingVideo()
const { $state } = useTrainingStore()
const user = useUserStore()
const tabButton = "focus-visible:outline-0 leading-none border border-black min-w-32 py-2 px-4 rounded-full ui-selected:bg-black ui-selected:text-white ui-not-selected:bg-transparent ui-not-selected:text-black";

onMounted(async()=>{
    const videos = await getMyVideos()
    const assosiate_videos = await getAssociateVideos()
})

const videoDetail = ref(null)

const dialog = reactive({
    edit: false,
    delete: false,
    upload: false,
    record: false,
})

const toggleDialog = (modal, val,data) => {

    videoDetail.value = data

    if (modal == 'upload') {
        dialog.upload = val
    }
    else if (modal == 'record') {
        dialog.record = val
    }
    else if (modal == 'delete') {
        dialog.delete = val
    }
    else if (modal == 'edit') {
        dialog.edit = val
    }

}

</script>