<template>
    <ModalsBase modalWidth="max-w-[460px]">
        <template #body>
            <form @submit.prevent="deleteVideo">
                <div class="text-center mb-8">
                    <h2 class="mb-3">Warning!</h2>
                    <p class="text-themegray">Are you sure you want to delete this video?</p>
                </div>
                <div class="flex gap-4 mt-6">
                    <Button variant="outline" class="w-full" @click="closeModal()" :disabled="loading" >CANCEL</Button>
                    <Button class="w-full" type="submit" :loading="loading" :disabled="loading" >Yes</Button>
                </div>
            </form>
            
        </template>
    </ModalsBase>
    
</template>

<script setup>

const props = defineProps(['data'])
const emit = defineEmits(['close'])

const video_id= ref("")
const loading= ref(false)

const {deleteMyVideo} = await useTrainingVideo()

onMounted(() => {
    video_id.value = props.data.id
})

const closeModal = () => {
    emit('close', 'delete', false)
}


const deleteVideo = async() => {
    try {
        loading.value = true
        await deleteMyVideo(video_id.value)
    } catch (error) {
        console.log("Error updating : ",error)
    }finally{
        loading.value = false
        closeModal()
    }
}

</script>