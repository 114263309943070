import { useAxiosPost } from "./api.js";


export const useTrainingVideo = async () =>{

    const store = useTrainingStore()
    const errorStore = useErrorStore()

    const uploadMyVideo  = async (data) =>{
        try {
            const {data:video,message} = await useAxiosFormDataPost('media',data)
            console.log(video,"Upload Data")
            store.addMyVideo(video)
            errorStore.showError(message,true,'success')
            return video
        } catch (error) {
            console.log("Training Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getMyVideos = async () => {
        try {
            const {data:videos,message} = await useAxiosGet('media/me')
            store.setMyVideos(videos)
            return videos
        } catch (error) {
            console.log("Training Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getAssociateVideos = async () => {
        try {
            const {data:videos,message} = await useAxiosGet('media/associates')
            store.setAssosiateVideos(videos)
            return videos
        } catch (error) {
            console.log("Training Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const updateMyVideoDetails = async (id,title) => {
        try {
            const {data:video,message} = await useAxiosPatch(`media/${id}`,{title:title})
            store.updateVideoDetail(id,video)
            errorStore.showError(message,true,'success')
            return video
        } catch (error) {
            console.log("Training Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteMyVideo = async (id) => {
        try {
            const {data:video,message} = await useAxiosDelete(`media/${id}`)
            store.removeMyVideo(id)
            errorStore.showError(message,true,'success')
            return
        } catch (error) {
            console.log("Training Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getDownloadLink = async (bucket,key) =>{
        const url = await useAxiosPost('download',{
            bucket:bucket,
            key:key
        })
        return url
    }

    return {
        uploadMyVideo,
        getMyVideos,
        getAssociateVideos,
        updateMyVideoDetails,
        deleteMyVideo,
        getDownloadLink
    }
}