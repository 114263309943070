<template>
    <ModalsBase modalWidth="max-w-[460px]">
        <template #header>
            <div class="text-center mb-8">
                <h2 class="mb-3">Edit project</h2>
            </div>
        </template>
        <template #body>
            <form action="" @submit.prevent="editVideoDetail">
                <div class="mb-6">
                    <Input id="name" label="Name" placeholder="Choose new project name" v-model="title"  :disabled="loading" />
                </div>
                <div class="flex gap-4 mt-6">
                    <Button variant="outline" type="submit" class="w-full" @click="closeModal()" :disabled="loading" >CANCEL</Button>
                    <Button class="w-full" type="submit" :disabled="loading" :loading="loading" >UPDATE</Button>
                </div>
            </form>
        </template>
    </ModalsBase>
</template>

<script setup>
const props = defineProps(['data'])
const emit = defineEmits(['close'])

const title = ref("")
const video_id= ref("")
const loading= ref(false)

const {updateMyVideoDetails} = await useTrainingVideo()

onMounted(() => {
    video_id.value = props.data.id
    title.value = props.data.title
})

const closeModal = () => {
    emit('close', 'edit', false)
}

const editVideoDetail = async() => {
    try {
        loading.value = true
        const video = await updateMyVideoDetails(video_id.value,title.value)
    } catch (error) {
        console.log("Error updating : ",error)
    }finally{
        loading.value = false
        closeModal()
    }
}

</script>